export const columnsDataColumnsBanners = [
  {
      Header: "NOME",
      accessor: "name",
  },
  {
      Header: "DATA",
      accessor: "created_at",
  },
  {
      Header: "AÇÕES",
      accessor: "actions",
  },
];
