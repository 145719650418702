/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useMemo, useState } from "react";
import { v4 } from "uuid";
import {
useGlobalFilter,
usePagination,
useSortBy,
useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteBanners } from "services/banners";
import { CreateBanners } from "services/banners";
import { EditBanners } from "services/banners";
import Dropzone from "components/dropzone";
import { getNameWithExtensionFromUrl } from "utils/masks";

export default function BannersTable(props) {
  const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt } = props;

  const { user } = useAuthContext();
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const tableInstance = useTable(
      {
      columns,
      data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination
  );

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [isOpen, setIsOpen] = useState(false);
  const [infoRow, setInfoRow] = useState(null);
  const [idFile, setIdFile] = useState(1);

  const onClose = () => {
      setIsOpen(false);
      setInfoRow(null);
  };

  const validationSchema = Yup.object().shape({
      file: Yup.array().min(1, 'Adicione uma imagem').required('Adicione a imagem do banner.'),
      name: Yup.string().required('O nome é obrigatório'),
  });

  const onClickEdit = (item) => {
      setInfoRow(item);
      setIsOpen(true);
  };

  const onClickDelete = async (item) => {
      await DeleteBanners(item.id, user?.token);
      getList();
  };

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}
  >
      <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
          <SearchBar
              placeholder="Buscar banner"
              value={searchTxt}
              setValue={setSearchTxt}
          />
          <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant="solid" bg="main.700" color="white">
              Criar banner
          </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent>
              <ModalHeader>
                  {infoRow ? 'Editar banner' : 'Criar banner'}
                  <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um banner`}</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" alignItems="center">
                  <Formik
                      initialValues={{
                          file: infoRow?.file ? [infoRow.file] : [],
                          name: infoRow?.name ? infoRow.name : '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async (values, helpers) => {
                          try {
                              const response = !infoRow?.id ? await CreateBanners(
                                  values.file[0],
                                  values.name,
                                  user?.token,
                              ) : await EditBanners(
                                  values.file[0],
                                  values.name,
                                  infoRow?.id,
                                  user?.token,
                              );
                              if (response?.status === 200 || response?.status === 201) {
                                  toast(response?.message || 'Banner salvo com sucesso!');
                                  helpers.setSubmitting(false);
                                  onClose();
                                  getList();
                              } else {
                                  helpers.setStatus({ success: false });
                                  helpers.setErrors({ submit: response?.message });
                                  helpers.setSubmitting(false);
                              }
                          } catch (err) {
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: err.message });
                              helpers.setSubmitting(false);
                          }
                      }}
                  >
                      {(props) => (
                          <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Field name='name'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Nome<Text color="main.700">*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Digitar um nome'
                                                fontWeight='500'
                                                size='lg'
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name='file'>
                                    {({ form }) => (
                                        <FormControl isInvalid={form.errors.file && form.touched.file}>
                                            <Flex display="flex" flexDirection="column" maxW="100%">
                                                <Dropzone
                                                    minH="60px"
                                                    w="100%"
                                                    borderRadius="10px"
                                                    mt="24px"
                                                    onImageSelect={(file) => {
                                                        const uuid = v4();
                                                        setIdFile(uuid);
                                                        form.setFieldValue('file', file);
                                                    }}
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    content={
                                                        <Box display="flex" flexDirection="column" gap="10px">
                                                            {form?.values?.file?.length > 0 ? (
                                                                <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(form?.values?.file[0]), idFile)}</Text>
                                                            ) : (
                                                                <>
                                                                    <Text color='blue.500' fontSize='xs' fontWeight='400'>Clique aqui para selecionar uma imagem.</Text>
                                                                    <Text color='secondaryGray.700' fontSize='10px' fontWeight='400'>Formatos aceitos: PNG, JPEG, JPG</Text>
                                                                </>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            </Flex>
                                            <FormErrorMessage>{form.errors.file}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                              {props.errors.submit && (
                                  <Text
                                      color={props.status.success ? 'green.500' : 'red.500'}
                                      fontSize='small'
                                      fontWeight='400'
                                      marginTop="20px"
                                  >
                                      {props.errors.submit}
                                  </Text>
                              )}
                              <Button
                                  variant='solid'
                                  bg="main.700"
                                  color="white"
                                  isLoading={props.isSubmitting}
                                  type="submit"
                                  alignSelf="flex-end"
                                  w="150px"
                                  marginTop="24px"
                              >
                                  {infoRow ? 'Editar banner' : 'Criar banner'}
                              </Button>
                          </Form>
                      )}
                  </Formik>
              </ModalBody>

              <ModalFooter>
              </ModalFooter>
          </ModalContent>
      </Modal>
    <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()} key={index}>
              {row.cells.map((cell, index) => {
                let data = "";
                if (cell.column.Header === "AÇÕES") {
                  return (
                      <Flex align='center' pl="30px" pt="5px">
                          <Menu row={cell.row.original} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                      </Flex>
                  )
                } else if (cell.column.Header === "DATA") {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                    </Text>
                  );
                } else {
                  data = (
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                      {cell.value}
                    </Text>
                  );
                }
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {data}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
      />
      <ToastContainer />
  </Card>
);
}
