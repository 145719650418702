import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdLock,
} from "react-icons/md";
import {
    FaBluetooth,
    FaBuilding,
    FaBullhorn,
} from "react-icons/fa";
// import { FaChartSimple } from "react-icons/fa6";

// Admin Imports
import Main from "views/admin/default";
// import Map from "views/admin/analitycs";
import Bluetooth from "views/admin/bluetooth";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import forgotPasswordCentered from "views/auth/forgotPassword";
import Banners from "views/admin/banners";

export const formatRoutes = () => {
    const isAuthenticated = window.localStorage.getItem('@indoor_authenticated');

    const routesAuth = [
        {
            name: "Sign In",
            layout: "/auth",
            path: "/sign-in",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: SignInCentered,
            hidden: true,
        },
        {
            name: "Recover Password",
            layout: "/auth",
            path: "/forgot-password",
            icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
            component: forgotPasswordCentered,
            hidden: true,
        },
    ];

    const routesAdm = [
        {
          name: "Empresas",
          layout: "/admin",
          path: "/default",
          icon: <Icon as={FaBuilding} width='20px' height='20px' color='white' />,
          component: Main,
        },
        {
          name: "BLE's",
          layout: "/admin",
          path: "/ble",
          icon: <Icon as={FaBluetooth} width='20px' height='20px' color='white' />,
          component: Bluetooth,
        },
        {
          name: "Banners",
          layout: "/admin",
          path: "/banners",
          icon: <Icon as={FaBullhorn} width='20px' height='20px' color='white' />,
          component: Banners,
        },
        // {
        //   name: "Analitycs",
        //   layout: "/admin",
        //   path: "/analitycs",
        //   icon: <Icon as={FaChartSimple} width='20px' height='20px' color='inherit' />,
        //   component: Map,
        // },
        // {
        //   name: "Configurações de perfil",
        //   layout: "/admin",
        //   path: "/profile",
        //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
        //   component: Profile,
        //   hidden: true,
        // },
    ];

    if (isAuthenticated) {
        return [
            ...routesAuth,
            ...routesAdm,
        ];
    }
    
    return routesAuth;
}
